// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dancedancepose-js": () => import("/opt/build/repo/src/pages/dancedancepose.js" /* webpackChunkName: "component---src-pages-dancedancepose-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-spot-js": () => import("/opt/build/repo/src/pages/spot.js" /* webpackChunkName: "component---src-pages-spot-js" */),
  "component---src-pages-tastygravy-js": () => import("/opt/build/repo/src/pages/tastygravy.js" /* webpackChunkName: "component---src-pages-tastygravy-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

